<template>
  <div class="apply-success">
    <van-nav-bar
      :title="isUnder ? '' : '支付成功'"
      fixed
      placeholder
      style="width: 100%"
    >
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>

    <span class="top-box" :style="kefuQrCodeUrl ? 'margin-top: 30px;' : 'margin-top: 100px;'">
      <!-- <img class="img" src="../../../assets/other/pay_success.png" /> -->
      <span class="iconfont icon-success"></span>
      <span class="tip-text">{{
        isUnder ? "请尽快联系驾校老师进行支付确认" : "支付成功"
      }}</span>
      <!-- <span class="text" v-if="mobile">请尽快联系老师进行支付确认</span> -->
    </span>

    <span class="kf-box" v-if="kefuQrCodeUrl">
      <img class="kf-img" :src="kefuQrCodeUrl" alt="" />
      <span class="kf-content1">扫码添加驾校客服</span>
      <span class="kf-content2">尊享无忧驾考服务</span>
    </span>

    <a class="red-btn" :href="contract" v-if="mobile">联系老师</a>
    <div class="red-btn" v-else @click="lookOrder">查看订单</div>

    <div class="grey-btn" @click="toHome">返回主页</div>
  </div>
</template>

<script>
import { Icon, NavBar } from "vant";
import { OrderDetail_Get } from "@/api/order";

export default {
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
  },
  computed: {
    contract() {
      return "tel:" + this.mobile;
    },
  },
  props: {
    params: [Object],
  },
  data() {
    return {
      orderList: [],
      mobile: null,
      isUnder: null,
      kefuQrCodeUrl: null,
    };
  },

  mounted() {
    let query = this.$router.currentRoute.query;
    this.mobile = query.mobile;
    this.orderId = "";
    if (this.params) {
      this.mobile = this.params.mobile;
      this.isUnder = this.params.isUnder;
      this.orderId = this.params.orderId;
    }

    if (this.orderId) {
      this.$reqGet(OrderDetail_Get + this.orderId, {}).then((res) => {
        this.kefuQrCodeUrl = res.map.object.kefuQrCodeUrl;
      });
    }
  },

  methods: {
    toDetail(id) {
      this.$router.push({ name: "classDetail", params: { itemId: id } });
    },

    lookOrder() {
      this.$router.push({ name: "orderList" });
    },

    toHome() {
      this.$router.push({ name: "home" });
    },

    onClickLeft() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="less">
.apply-success {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  .kf-box {
    width: 228px;
    height: 228px;
    border: 1px solid #666666;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
    .kf-img {
      width: 129px;
      height: 129px;
      margin-top: 18px;
    }
    .kf-content1 {
      color: #666666;
      font-size: 16px;
      margin-top: 16px;
    }
    .kf-content2 {
      color: #666666;
      font-size: 16px;
      margin-top: 4px;
    }
  }
  .top-box {
    margin-top: 100px;
    width: 343px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .iconfont {
      font-size: 28px;
    }

    .img {
      width: 34px;
      height: 34px;
    }
    .tip-text {
      margin-top: 28px;
      text-align: center;
      width: 100%;
      font-size: 16px;
      color: #333;
    }
    .text {
      font-size: 16px;
      color: #333;
      margin-top: 2px;
      text-align: center;
      width: 100%;
    }
  }
  .red-btn {
    width: 343px;
    height: 50px;
    background: var(--theme-color);
    border-radius: 25px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    bottom: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .grey-btn {
    width: 343px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
